import React from "react";
import styled from "styled-components";
import SolutionsSection from "../components/index/solutionsSection";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubscribeForm from "../components/subscribeForm";
import { HeaderPlaceholder } from "../styles/ui.styles";

const Solutions: React.FC = () => {
  return (
    <>
      <SEO title={"Solutions"} description={"Solutions"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Solutions</h1>
        </Wrapper>
        <SolutionsSection />
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Solutions;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
`;
